<template>
  <section class="accessAdministration">
    <label v-for="field in listAdministration" :key="field.name" class="accessAdministration__label">
      <input type="checkbox" v-model="field.isChecked" :value="field.isChecked" :name="field.name" @change="toggleCheck(field)" />
      <span class="accessAdministration__name">
        {{ field.name }}
      </span>
    </label>
  </section>
</template>

<script>
export default {
  props: ["policy", "user"],
  data() {
    return {
      listAdministration: [],
      selectedOptions: this.user.polices["Access-reservations-CMS-fields"]?.payload || [],
    };
  },
  methods: {
    createListAdmin() {
      return ["Empleados", "Aspirantes", "CRM sitio web"].map((field) => ({
        name: field,
        isChecked: !!this.user.polices["Access-reservations-CMS-fields"]?.payload?.includes(field),
      }));
    },

    toggleCheck(field) {
      if (field.isChecked) {
        this.selectedOptions.push(field.name);
      } else {
        this.selectedOptions = this.selectedOptions.filter((fieldName) => fieldName !== field.name);
        field.isChecked = !field.isChecked;
      }
      this.$emit("result-updated", { politic: this.selectedOptions });
    },

    getResult() {
      return !!this.selectedOptions.length;
    },

    refresOptions() {
      this.listAdministration = this.createListAdmin();
    },
  },

  mounted() {
    this.listAdministration = this.createListAdmin();
  },
};
</script>

<style lang="scss">
.accessAdministration {
  &__label {
    display: block;
    margin: 10px 0;
  }
  &__name {
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
